<template>
  <div class="app-container">
    <appHeader />
    <sidebar />
    <div class="app-body">
      <div class="app-wrapper">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import appHeader from './header.vue'
import sidebar from './sidebar.vue'
export default {
  components: {
    appHeader,
    sidebar,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  created() {
    const userAgent = navigator.userAgent
    // 匹配设备型号信息
    const modelRegex =
      /(Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini)/

    const match = userAgent.match(modelRegex)
    if (match) {
      this.isMobile = true
    }
  },
}
</script>
<style lang="less">
.app-body {
  padding: 60px 10px 10px 190px;
  background-color: #eaedf0;

  .app-wrapper {
    position: relative;
    background: linear-gradient(226deg, #ffffff 0%, #fcfcfc 100%);
    min-height: calc(~'100vh - 84px');
    border-radius: 5px;
    padding: 18px;
  }
}
</style>