import axios from "axios";
import { message } from "ant-design-vue";
import { domain } from "@/siteInfo.js";
import store from "@/store/store.js";
import router from "../router/router.js";
import Qs from 'qs'

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let out = false
let axiosPromiseArr = []

const httpRequest = axios.create({
  baseURL:domain + "/admin/v1",
  timeout: 5000
})

let freeApi = [
	'privacyServiceInfo',
	'aboutUsInfo'
]
// 请求拦截
httpRequest.interceptors.request.use(
  function (config) {
    config.cancelToken = new axios.CancelToken(cancel => {
      axiosPromiseArr.push({ cancel })
    })
    if (store.state.token) {
      config.headers['Authorization'] = 'Bearer ' + store.state.token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截
httpRequest.interceptors.response.use(
  function (response) {
    let code = response.data.code
    // 跳转到登录页面
    if (code == -10) {
		axiosPromiseArr.forEach((ele, index) => {
		    ele.cancel('我中断一个请求康康')
		    delete axiosPromiseArr[index]
		}) 
		return Promise.reject(response.data)
    }else{
		console.log("zhebdaskdjaskldskdljaskl")
		return response.data
	}
    // Message({ type: 'error', message: response.data.msg })
  
  },
  function (error) {
    if (error && error.message) {
        return Promise.reject(error)
    }
    console.log(error)
    return Promise.reject(error)
  }
)

const fetch = (url, params = {}) => {
	return new Promise((resolve, reject) => {
		let a = freeApi.indexOf(url)
		if (!store.state.token && a < 0) {
			message.error("登录失效！", 1.5);
			router.push({ path: "/login" });
			reject('登录失效！')
		}
		httpRequest({
				url: url,
				data: params,
				method: 'post'
			})
		.then((res) => {
			console.log(domain + "/admin/v1" + url + ":", res);
			resolve(res);
		})
		.catch((err) => {
			// console.log(domain + "/api/admin" + url + ":", err);
			console.log('shibaixinxi::::', err)
			if(err.code == -10) {
				message.error("登录失效！", 1.5);
				router.push({ path: "/login" });
			}else{
				message.error("网络开了小差", 1.5);
			}
 			reject(err);
		});
	});
};

const uploadFile =(url,params = {}) => {
	console.log(params)
	

	return new Promise((resolve,reject) => {
		httpRequest({
			url: domain+'/admin/v1/index/'+ url,
			data: params,
			method: 'post'
		}).then(res => {
			resolve(res)
		}).catch(err =>{
			reject(err)
		})
	})
}

export {
	fetch,
	axiosPromiseArr,
	uploadFile
}
